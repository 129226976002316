import { DocumentType, ProduitType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { UserProps } from '~/utils/requireUser';

import ProductWithSlots from '../../../containers/Payment/ProductWithSlots';
import TemplatePublic from '../../../templates/public';

const PageEspaceProduct: FC<PageProps & Partial<UserProps>> = ({
  params: { espaceId, produitId },
}) => {
  const [produit, setProduit] = useState<DocumentType<ProduitType>>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const produitData = new ProduitData({ espaceId });
    produitData
      .findById(produitId)
      .then(setProduit)
      .catch(e => setError(e.message));
  }, [espaceId, produitId]);

  if (!produit) {
    return null;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!produit) {
    return <div>Chargement en cours</div>;
  }

  const { label, hasSlots } = produit;

  return (
    <TemplatePublic espaceId={espaceId}>
      <h1 className="text-xl text-center mb-12">{label}</h1>

      {hasSlots && <ProductWithSlots produit={produit} />}
    </TemplatePublic>
  );
};

export default PageEspaceProduct;
